var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form_container"},[_c('h1',{staticClass:"is-size-4 has-text-centered has-text-weight-light mt-0 mb-6"},[_vm._v(" Nouveau mot de passe ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)}}},[_c('b-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showSuccessMessage),expression:"!showSuccessMessage"}],attrs:{"label":"Mot de passe","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.password.$error },"message":[
        { 'Champs requis': !_vm.$v.form.password.required && _vm.$v.form.password.$dirty },
        { 'Minimum 8 caractères': !_vm.$v.form.password.minLength },
      ]}},[_c('b-input',{attrs:{"rounded":"","type":"password","password-reveal":""},model:{value:(_vm.$v.form.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.password.$model"}})],1),_c('b-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showSuccessMessage),expression:"!showSuccessMessage"}],attrs:{"label":"Confirmation mot de passe","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.passwordConfirmation.$error },"message":[
        {
          'Les deux mots de passe doivent être identiques':
          _vm.$v.form.passwordConfirmation.$error
        },
      ]}},[_c('b-input',{attrs:{"rounded":"","type":"password","password-reveal":""},model:{value:(_vm.$v.form.passwordConfirmation.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.passwordConfirmation, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.passwordConfirmation.$model"}})],1),_c('b-notification',{staticClass:"mt-4",attrs:{"type":"is-light","aria-close-label":"Fermer message d'erreur","role":"alert","closable":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),(!_vm.showSuccessMessage)?_c('b-button',{staticClass:"mt-6",attrs:{"label":"Réinitialiser","disabled":_vm.$v.$invalid,"native-type":"submit","type":"is-secondary","expanded":"","rounded":""}}):_vm._e(),(_vm.showSuccessMessage)?_c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-center is-align-item-center"},[_c('div',[_c('SuccessCheck')],1),_c('p',{staticClass:"has-text-centered"},[_vm._v(" Modification du mot de passe effectuée succès ! ")]),_c('p',{staticClass:"has-text-centered"},[_vm._v(" Vous pouvez fermer cet onglet et commencer à utiliser l'application ! ")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }