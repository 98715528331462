
import { Component, Vue } from 'vue-property-decorator';
import {
  required,
  sameAs,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import SuccessCheck from '@/components/SuccessCheck.vue';
import authService from '@/services/auth/authService';

@Component({
  components: {
    SuccessCheck,
  },
})
export default class PasswordResetForm extends Vue {
  public form: any = {
    password: '',
    passwordConfirmation: '',
  };
  public showSuccessMessage = false;
  public error = '';
  public active = false;

  validations(): any {
    return {
      form: {
        password: {
          required,
          maxLength: maxLength(64),
          minLength: minLength(8),
        },
        passwordConfirmation: {
          sameAsPassword: sameAs('password'),
        },
      },
    };
  }

  async reset(): Promise<void> {
    if (!this.$v.$invalid) {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });
      const { token } = this.$route.query;

      this.active = false;
      try {
        await authService.resetPassword(String(token), this.form.password);
        this.showSuccessMessage = true;
      } catch (err) {
        const { data } = err.response;

        this.error = Array.isArray(data.message) ? data.message[0] : data.message;
        this.active = true;
      } finally {
        loadingComponent.close();
      }
    }
  }
}
