
import { Component, Vue } from 'vue-property-decorator';
import PasswordResetForm from '@/components/PasswordResetForm.vue';

@Component({
  components: {
    PasswordResetForm,
  },
})
export default class PasswordReset extends Vue {}
